import { isNativeAddress } from '@orbs-network/liquidity-hub-ui-sdk'
import { ChainId } from '@uniswap/sdk-core'
import { TokenBalanceCovalent } from 'components/AccountDrawer/MiniPortfolio/Tokens'
import {
  AVALANCHE_LIST,
  BASE_LIST,
  BERA_TESTNET_LIST,
  BTTC_LIST,
  EON_LIST,
  FANTOM_LIST,
  GOAT_TESTNET_LIST,
  OPTIMISM_LIST,
  PLASMA_BNB_LIST,
  SONIC_TESTNET_LIST,
  UNI_LIST,
} from 'constants/lists'

const HIDE_SMALL_USD_BALANCES_THRESHOLD = 1

type FANTOM_LIST = {
  [key: string]: {
    name: string
    symbol: string
    decimals: number
    address: string
    logoURI: string
  }
}

async function loadSafeTokenLists(chainId: number | undefined) {
  let url = ''
  if (chainId === ChainId.FANTOM) {
    url = FANTOM_LIST
  } else if (chainId === ChainId.BIT_TORRENT_MAINNET) {
    url = BTTC_LIST
  } else if (chainId === ChainId.EON) {
    url = EON_LIST
  } else if (chainId === ChainId.BERA_TESTNET) {
    url = BERA_TESTNET_LIST
  } else if (chainId === ChainId.BASE) {
    url = BASE_LIST
  } else if (chainId === ChainId.AVALANCHE) {
    url = AVALANCHE_LIST
  } else if (chainId === ChainId.BNB) {
    url = PLASMA_BNB_LIST
  } else if (chainId === ChainId.OPTIMISM) {
    url = OPTIMISM_LIST
  } else if (chainId === ChainId.SONIC_TESTNET) {
    url = SONIC_TESTNET_LIST
  } else if (chainId === ChainId.GOAT_TESTNET) {
    url = GOAT_TESTNET_LIST
  } else {
    url = UNI_LIST
  }
  const response = await fetch(url)
  const data = await response.json()
  return data.tokens
}

export async function splitHiddenTokens(
  tokenBalances: TokenBalanceCovalent[],
  chainId: number | undefined,
  options?: {
    hideSmallBalances?: boolean
  }
) {
  const visibleTokens: TokenBalanceCovalent[] = []
  const hiddenTokens: TokenBalanceCovalent[] = []
  const fantomList = await loadSafeTokenLists(chainId) // Convert fetched list to a set of addresses for faster lookup
  const fantomAddresses = new Set(fantomList.map((token: any) => token.address.toLowerCase()))

  tokenBalances.forEach((tokenBalance) => {
    const isValidValue =
      typeof tokenBalance.quote === 'undefined' || options?.hideSmallBalances === false || meetsThreshold(tokenBalance)
    const isInFantomList = fantomAddresses.has(tokenBalance.contract_address.toLowerCase())
    const isNative = isNativeAddress(tokenBalance.contract_address.toLowerCase())

    if ((isValidValue && isInFantomList) || isNative) {
      visibleTokens.push(tokenBalance)
    } else {
      hiddenTokens.push(tokenBalance)
    }
  })

  return { visibleTokens, hiddenTokens }
}

function meetsThreshold(tokenBalance: TokenBalanceCovalent) {
  const value = tokenBalance.quote ?? 0
  return value > HIDE_SMALL_USD_BALANCES_THRESHOLD
}
