import { ChainId } from '@uniswap/sdk-core'
import { DEFAULT_CHAIN_ID } from 'constants/misc'
import { Location } from 'react-router-dom'

export function getChainFromUrl(location: Location) {
  const query = new URLSearchParams(location.search)
  const param = query.get('chain')
  switch (param) {
    case 'fantom':
      return ChainId.FANTOM
    case 'bit_torrent':
      return ChainId.BIT_TORRENT_MAINNET
    case 'horizen':
      return ChainId.EON
    case 'bera_testnet':
      return ChainId.BERA_TESTNET
    case 'sonic_testnet':
      return ChainId.SONIC_TESTNET
    case 'goat_testnet':
      return ChainId.GOAT_TESTNET
    default:
      return DEFAULT_CHAIN_ID
  }
}
