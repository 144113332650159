interface CustomSpinnerGrimAnimationProps {
  size?: string
}

export default function CustomSpinnerGrimAnimation({ size = '190px' }: CustomSpinnerGrimAnimationProps) {
  return (
    <img
      src="https://assets.spooky.fi/goat_spinner.gif"
      alt="Loading spinner animation"
      width={size}
      height={size}
      style={{ maxWidth: '100%', maxHeight: '100%' }}
    />
  )
}
