import { Trans } from '@lingui/macro'
import { ButtonPrimary } from 'components/Button'
import { AutoColumn } from 'components/Column'
import { RowBetween, RowFixed } from 'components/Row'
import Toggle from 'components/Toggle'
import {
  LARGE_MEDIA_BREAKPOINT,
  MAX_WIDTH_MEDIA_BREAKPOINT,
  MEDIUM_MEDIA_BREAKPOINT,
} from 'components/Tokens/constants'
import { filterStringAtom } from 'components/Tokens/state'
import { MouseoverTooltip } from 'components/Tooltip'
import TransactionConfirmationModal, { ConfirmationModalContent } from 'components/TransactionConfirmationModal'
import { useAllHarvest } from 'hooks/useHarvest'
import { useResetAtom } from 'jotai/utils'
import { Checkbox } from 'nft/components/layout/Checkbox'
import { ResponsiveButtonConfirmed } from 'pages/Pool/PositionPage'
import { darken } from 'polished'
import { useCallback, useEffect, useState } from 'react'
import { AlertTriangle } from 'react-feather'
import { useLocation, useNavigate } from 'react-router-dom'
import { Text } from 'rebass'
import { useIsTransactionPending } from 'state/transactions/hooks'
import styled, { useTheme } from 'styled-components'
import { ThemedText } from 'theme'
import { srcFarmV3 } from 'theme/assets'

import FarmTable from './FarmTable/FarmTable'

const ExploreContainer = styled.div`
  width: 100%;
  min-width: 320px;
  padding: 68px 12px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.md}px`}) {
    padding-top: 48px;
  }

  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.sm}px`}) {
    padding-top: 20px;
  }
`

const HeaderContainer = styled.div`
  background-image: url(${srcFarmV3});
  background-size: 100%;
  background-repeat: no-repeat;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  z-index: 0;
  position: relative;

  height: calc((100vw / 3) + 32px);
  aspect-ratio: 1200/553; /* Set your desired aspect ratio (height/width) */

  max-width: ${LARGE_MEDIA_BREAKPOINT};
  max-height: 390px;
  margin-bottom: -100px;

  @media only screen and (max-width: ${MEDIUM_MEDIA_BREAKPOINT}) {
    margin-bottom: -150px;
  }
`

const TitleContainer = styled.div`
  margin-bottom: 32px;
  max-width: ${MAX_WIDTH_MEDIA_BREAKPOINT};
  margin-left: auto;
  margin-right: auto;
  font-weight: 550;
  font-size: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
`
const FiltersContainer = styled.div`
  display: flex;
  gap: 8px;
  height: 40px;

  @media only screen and (max-width: ${MEDIUM_MEDIA_BREAKPOINT}) {
    order: 2;
  }
`
const SearchContainer = styled(FiltersContainer)`
  width: 100%;
  z-index: 2;

  @media only screen and (max-width: ${MEDIUM_MEDIA_BREAKPOINT}) {
    margin: 0px;
    order: 1;
  }
`
const FiltersWrapper = styled.div`
  display: flex;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 20px;
  color: ${({ theme }) => theme.neutral3};
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  @media only screen and (max-width: ${MEDIUM_MEDIA_BREAKPOINT}) {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    gap: 12px;
  }

  max-width: 1061px;
  position: relative;
  z-index: 2;
`

const ToggleWrapper = styled.div`
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.surface1};
  border: 1px solid ${({ theme }) => theme.surface3};
  border-radius: 12px;
  padding: 6px 12px;
  gap: 8px;
  z-index: 1000;
  transition: all ${({ theme }) => theme.transition.duration.fast} ease-in-out;

  &:hover {
    background-color: ${({ theme }) => darken(0.03, theme.surface1)};
    border-color: ${({ theme }) => theme.surface2};
  }
`

const ToggleLabel = styled.span`
  font-size: 14px;
  font-weight: 485;
  color: ${({ theme }) => theme.neutral2};
`
const StyledToggle = styled(Toggle).attrs({})`
  ${({ theme }) => `
    background-color: #20082cee;
    
    border: 1px solid #ff6b0055;
    border-radius: 999px;
    width: 40px;
    height: 24px;
    z-index: 1000;
    transition: all ${theme.transition.duration.fast} ease-in-out;

    &[data-isactive='true'] {
      background-color: #ff6b00;
      border-color: #ff6b00;
    }
    &:hover {
      background-color: #ff6b00;
      border-color: #ff6b00;
    }

    &[data-isactive='true']:hover {
      background-color: #ff6b00;
    }

    &::after {
      background-color: #ff6b00;
      width: 20px;
      height: 20px;
    }
  `}
`

// Add this new styled component

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 12px;
  background: ${({ theme }) => theme.surface1};
  border: 1px solid ${({ theme }) => theme.surface3};
  border-radius: 12px;
  cursor: pointer;
  transition: all ${({ theme }) => theme.transition.duration.fast} ease-in-out;

  &:hover {
    background: ${({ theme }) => darken(0.03, theme.surface1)};
    border-color: ${({ theme }) => theme.surface2};
  }
`

const CheckboxLabel = styled.span`
  font-size: 14px;
  font-weight: 485;
  margin-left: 4px;
  padding-right: 12px;
  color: ${({ theme }) => theme.neutral2};
`

const ToggleContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;

  @media only screen and (max-width: ${MEDIUM_MEDIA_BREAKPOINT}) {
    width: 100%;
    justify-content: flex-start;
  }
`

const DisclaimerHeader = styled.div`
  color: ${({ theme }) => theme.deprecated_accentWarning};
`

const DisclaimerContent = styled.div`
  display: flex;
  text-align: center;
  padding-bottom: 4px;
`

const DisclaimerContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 8px 12px;
  margin: 0 auto;
  border: 1px solid ${({ theme }) => theme.deprecated_accentWarning};
  border-radius: 12px;
  background-color: ${({ theme }) => theme.surface2};
  margin-bottom: 20px;
  align-items: center;

  max-width: 1061px;
  position: relative;
  z-index: 2;
`

// Add these styled components after the existing ToggleContainer component

const FarmTypeSelect = styled.select`
  background-color: ${({ theme }) => theme.surface1};
  color: ${({ theme }) => theme.neutral1};
  border: 1px solid ${({ theme }) => theme.surface3};
  border-radius: 12px;
  padding: 8px 12px;
  font-size: 14px;
  font-weight: 485;
  cursor: pointer;
  transition: all ${({ theme }) => theme.transition.duration.fast} ease-in-out;

  &:hover {
    background-color: ${({ theme }) => darken(0.03, theme.surface1)};
    border-color: ${({ theme }) => theme.surface2};
  }
`
const FilterButtons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
  gap: 10px;
`

// Add this helper after the styled components and before the FarmsV3 component
const getStoredVerifiedState = (): boolean => {
  const stored = null //localStorage.getItem('spooky-farm-verified-state')
  return stored === null ? true : stored === 'true'
}

// Add this helper next to getStoredVerifiedState
const getStoredExpiredState = (): boolean => {
  const stored = null //localStorage.getItem('spooky-farm-expired-state')
  return stored === null ? false : stored === 'true'
}

// Add these styled components
const RiskyFarmsCheckbox = styled(CheckboxContainer)<{ showComponent: boolean }>`
  display: ${({ showComponent }) => (showComponent ? 'flex' : 'none')};
  align-items: center;
  gap: 4px;

  border-color: ${({ theme }) => theme.deprecated_accentWarning};
  background: ${({ theme }) => `${theme.deprecated_accentFailureSoft}22`};
  transition: all ${({ theme }) => theme.transition.duration.fast} ease-in-out;

  &:hover {
    background: ${({ theme }) => `${theme.deprecated_accentWarning}`};
    border-color: ${({ theme }) => theme.deprecated_accentFailureSoft};
  }
`

const RiskyCheckboxLabel = styled(CheckboxLabel)`
  color: ${({ theme }) => theme.white};
  font-weight: 600;
  text-shadow: 0 0 10px ${({ theme }) => `${theme.deprecated_accentWarning}44`};
`

const WarningIcon = styled(AlertTriangle)`
  color: ${({ theme }) => theme.white};
  width: 16px;
  height: 16px;
  margin-right: 4px;
  filter: drop-shadow(0 0 4px ${({ theme }) => `${theme.deprecated_accentWarning}66`});
`

const FarmsV3 = () => {
  const resetFilterString = useResetAtom(filterStringAtom)
  const { onReward } = useAllHarvest()
  const [txHash, setTxHash] = useState<string | undefined>()
  const [attemptingTxn, setAttemptingTxn] = useState<boolean>(false)
  const [showConfirm, setShowConfirm] = useState<boolean>(false)
  const theme = useTheme()
  const isClaimAllPending = useIsTransactionPending(txHash ?? undefined)
  // Update expired farms initialization
  const [showExpiredFarms, setShowExpiredFarms] = useState(getStoredExpiredState())
  const [isOfficialFarms, setIsOfficialFarms] = useState(getStoredVerifiedState())
  const [includeRiskyFarms, setIncludeRiskyFarms] = useState(false)
  const [farmType, setFarmType] = useState<'official' | 'community'>(
    getStoredVerifiedState() ? 'official' : 'community'
  )
  const [isHovered, setIsHovered] = useState(false)
  const navigate = useNavigate()
  const [showRiskyFarms, setShowRiskyFarms] = useState(false)

  const pendingText = <Trans>Claim All Farms</Trans>

  const handleDismissConfirmation = useCallback(() => {
    setShowConfirm(false)
    setTxHash(undefined)
  }, [])

  const onClaimAll = useCallback(async () => {
    try {
      setAttemptingTxn(true)
      const response = await onReward()
      setTxHash(response.hash)
    } finally {
      setAttemptingTxn(false)
    }
  }, [onReward])

  function modalHeader() {
    return (
      <AutoColumn gap="sm" style={{ padding: '16px' }}>
        <RowBetween align="flex-end">
          <Text fontSize={16} fontWeight={535}>
            <Trans>Proceeding will claim all rewards from active farming</Trans>
          </Text>
          <RowFixed></RowFixed>
        </RowBetween>
        <ButtonPrimary mt="16px" onClick={onClaimAll} padding="10px">
          <Trans>Claim All</Trans>
        </ButtonPrimary>
      </AutoColumn>
    )
  }

  const location = useLocation()

  useEffect(() => {
    resetFilterString()
  }, [location, resetFilterString])

  const handleToggleVerified = () => {
    const newOfficialState = !isOfficialFarms
    setIsOfficialFarms(newOfficialState)
    setFarmType(newOfficialState ? 'official' : 'community')
    //localStorage.setItem('spooky-farm-verified-state', String(newOfficialState))
  }

  // Add handler for expired farms toggle
  const handleExpiredToggle = () => {
    const newExpiredState = !showExpiredFarms
    setShowExpiredFarms(newExpiredState)
    //localStorage.setItem('spooky-farm-expired-state', String(newExpiredState))
  }

  return (
    <>
      <TransactionConfirmationModal
        isOpen={showConfirm}
        onDismiss={handleDismissConfirmation}
        attemptingTxn={attemptingTxn}
        hash={txHash ?? ''}
        reviewContent={() => (
          <ConfirmationModalContent
            title={<Trans>Claim All</Trans>}
            onDismiss={handleDismissConfirmation}
            topContent={modalHeader}
          />
        )}
        pendingText={pendingText}
      />
      <ExploreContainer>
        <HeaderContainer></HeaderContainer>
        <FiltersWrapper>
          <ToggleContainer>
            <CheckboxContainer onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
              <Checkbox checked={!isOfficialFarms} onChange={handleToggleVerified} hovered={isHovered}>
                <CheckboxLabel>Community Farms</CheckboxLabel>
              </Checkbox>
            </CheckboxContainer>

            <RiskyFarmsCheckbox
              showComponent={!isOfficialFarms}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              <MouseoverTooltip
                text={
                  <Trans>
                    Riskier farms include pools with tokens not listed in the Spooky Token List.
                    <br />
                    Exercise extra caution as these tokens may be unverified or potentially malicious.
                  </Trans>
                }
                placement="top"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <WarningIcon
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                />
              </MouseoverTooltip>
              <Checkbox
                checked={includeRiskyFarms}
                onChange={() => setIncludeRiskyFarms(!includeRiskyFarms)}
                hovered={isHovered}
                className="risky-checkbox"
              >
                <RiskyCheckboxLabel>Riskier Farms</RiskyCheckboxLabel>
              </Checkbox>
            </RiskyFarmsCheckbox>

            <ToggleWrapper>
              <ToggleLabel>Expired</ToggleLabel>
              <StyledToggle id="expired-farms-toggle" isActive={showExpiredFarms} toggle={handleExpiredToggle} />
            </ToggleWrapper>
          </ToggleContainer>
          <ResponsiveButtonConfirmed
            width="50px"
            style={{ borderRadius: '12px' }}
            margin="1px"
            padding="4px 8px"
            onClick={() => navigate('/submit-farm')}
          >
            <>
              <ThemedText.DeprecatedMain color={theme.white}>
                <Trans>Create Farm</Trans>
              </ThemedText.DeprecatedMain>
            </>
          </ResponsiveButtonConfirmed>
        </FiltersWrapper>
        {!isOfficialFarms && (
          <DisclaimerContainer>
            <DisclaimerHeader>Warning</DisclaimerHeader>
            <DisclaimerContent>
              Anyone can create a farm! Farming low liquidity or lesser known pairs/reward tokens could result in a loss of
              money through (im)permanent loss!
            </DisclaimerContent>
          </DisclaimerContainer>
        )}

        <FarmTable
          showExpiredFarms={showExpiredFarms}
          farmType={farmType}
          isVerified={isOfficialFarms}
          showRiskyFarms={includeRiskyFarms}
        />
      </ExploreContainer>
    </>
  )
}

export default FarmsV3
