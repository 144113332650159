import { GoatSwapText, SpookySwapText } from 'components/NavBar/SpookySwapText'
import { GoatIcon, SpookyIcon, XBooIcon } from 'nft/components/icons'
import { getDefaultChain } from 'utils/env'

import darkImage from '../assets/images/404-page-dark.png'
import SpookyTeamLogo from '../components/About/images/SpookySwapFooter.svg'

// Farm
const srcMapFarm: any = {
  SpookySwap: 'https://assets.spooky.fi/farm.png',
  GoatSwap: 'https://assets.spooky.fi/goat-farm.png',
}

export const srcFarm = srcMapFarm[getDefaultChain()]

const srcMapFarmV3: any = {
  SpookySwap: 'https://assets.spooky.fi/farm-v2.png',
  GoatSwap: 'https://assets.spooky.fi/goat-farm.png',
}

export const srcFarmV3 = srcMapFarmV3[getDefaultChain()]

// Liquidity
const srcMapLiquidityLeft: any = {
  SpookySwap: 'https://assets.spooky.fi/LiquidityCats_Left.png',
  GoatSwap: 'https://assets.spooky.fi/liqgoatleft.png',
}

export const srcLiquidityLeft = srcMapLiquidityLeft[getDefaultChain()]

const srcMapLiquidityRight: any = {
  SpookySwap: 'https://assets.spooky.fi/LiquidityCats_Right.png',
  GoatSwap: 'https://assets.spooky.fi/liqgoatright.png',
}

export const srcLiquidityRight = srcMapLiquidityRight[getDefaultChain()]

// Bridge
const srcMapBridgeLeft: any = {
  SpookySwap: 'https://assets.spooky.fi/bridge/portal_left.png',
  GoatSwap: 'https://assets.spooky.fi/goat_portal_left.png',
}

export const srcBridgeLeft = srcMapBridgeLeft[getDefaultChain()]

const srcMapBridgeRight: any = {
  SpookySwap: 'https://assets.spooky.fi/bridge/portal_right.png',
  GoatSwap: 'https://assets.spooky.fi/goat_portal_right.png',
}

export const srcBridgeRight = srcMapBridgeRight[getDefaultChain()]

// Swap
const srcMapGrim: any = {
  SpookySwap: 'https://assets.spooky.fi/mirror_grim.png',
  GoatSwap: 'https://assets.spooky.fi/goatportalleft.png',
}

export const srcGrim = srcMapGrim[getDefaultChain()]

const srcMapAce: any = {
  SpookySwap: 'https://assets.spooky.fi/mirror_ace.png',
  GoatSwap: 'https://assets.spooky.fi/goatportalright.png',
}

export const srcAce = srcMapAce[getDefaultChain()]

// Socials
export const discordMap: any = {
  SpookySwap: 'https://discord.com/invite/weXbvPAH4Q',
  GoatSwap: 'https://discord.com/invite/nev2NEwP9A',
}

export const twitterMap: any = {
  SpookySwap: 'https://twitter.com/Spookyswap',
  GoatSwap: 'https://x.com/Goatswap_fi',
}

export const telegramMap: any = {
  SpookySwap: 'https://t.me/SpookySwapCommunity',
  GoatSwap: 'https://t.me/GoatswapTelegram',
}

export const mediumMap: any = {
  SpookySwap: 'https://spookyswap.medium.com/',
  GoatSwap: 'https://medium.com/@goatswapongoat',
}

export const githubMap: any = {
  SpookySwap: 'https://github.com/SpookySwapV3',
  GoatSwap: 'https://github.com/SpookySwapV3',
}

export const formsMap: any = {
  SpookySwap: 'https://docs.google.com/forms/d/e/1FAIpQLSeeB8UO_NmK2DSTPF5WUzz8wkRj8mxWPuRzZibeODmcaXk4jg/viewform',
  GoatSwap: 'https://forms.gle/GqYTnbcyygGGspUa7',
}

export const bugMap: any = {
  SpookySwap: 'https://www.immunefi.com/bounty/spookyswap',
  GoatSwap: 'https://forms.gle/jAznwumnBfHDkdNc7',
}

export const redditMap: any = {
  SpookySwap: 'https://www.reddit.com/r/SpookySwap/',
}

export const forumMap: any = {
  SpookySwap: 'https://forum.spooky.fi/',
}

export const voteMap: any = {
  SpookySwap: 'https://forum.spooky.fi/',
}

export const docsMap: any = {
  SpookySwap: 'https://docs.goatswap.fi/',
  GoatSwap: 'https://docs.goatsw.app/',
}

export const faqsMap: any = {
  SpookySwap: 'https://docs.goatswap.fi/getting-started/faq',
  GoatSwap: 'https://docs.goatsw.app/getting-started/faq',
}

export const brandAssetsMap: any = {
  SpookySwap: 'https://docs.goatswap.fi/links/brand-assets',
  GoatSwap: 'https://docs.goatsw.app/links/brand-assets',
}
// Navbar Icon

const navbarIconMap: Record<string, React.ElementType> = {
  SpookySwap: SpookyIcon,
  GoatSwap: GoatIcon,
}

export const NavbarIcon = navbarIconMap[getDefaultChain()]

// Navbar SwapText

const navbarTextMap: Record<string, React.ElementType> = {
  SpookySwap: SpookySwapText,
  GoatSwap: GoatSwapText,
}

export const NavbarText = navbarTextMap[getDefaultChain()]

// Footer Logo

const footerLogoMap: Record<string, string> = {
  SpookySwap: SpookyTeamLogo,
  GoatSwap: '',
}

export const FooterLogo = footerLogoMap[getDefaultChain()]

// lottie animations

export const lottieLandingMap: any = {
  SpookySwap: 'https://cdn.lottielab.com/l/6DCE8RCuzdb3wN.json',
  GoatSwap: 'https://assets.spooky.fi/goat_lottie_bg_large.json',
}

export const lottieLandingMobileMap: any = {
  SpookySwap: 'https://cdn.lottielab.com/l/33CN6j4ADjXEtQ.json',
  GoatSwap: 'https://assets.spooky.fi/goat_lottie_bg.json',
}

// lottie goat background

const lottieLLandingBGMap: any = {
  // GoatSwap: 'https://assets.spooky.fi/goat_background_lottie2.png',
}

export const lottieLLandingBG = lottieLLandingBGMap[getDefaultChain()]

// background

export const backgroundMoonMap: any = {
  SpookySwap: 'https://assets.spooky.fi/background/moon_cat_.png',
  GoatSwap: 'https://assets.spooky.fi/moon_goat_.png',
}

export const backgroundCloudCatMap: any = {
  SpookySwap: 'https://assets.spooky.fi/background/cloud_cat.png',
  GoatSwap: 'https://assets.spooky.fi/cloud_goat.png',
}

export const backgroundGradientMap: any = {
  SpookySwap: 'radial-gradient(circle at bottom, rgba(50, 94, 128, 1) 10%, rgba(16, 30, 62, 1) 90%)',
  GoatSwap: 'radial-gradient(circle at bottom, #1e192b 10%, #1e192b 90%)',
}

// const backgroundImageMap: any = {
//   SpookySwap: '',
//   GoatSwap: 'https://assets.spooky.fi/space_goat.png',
// }

// export const backgroundImage = backgroundImageMap[getDefaultChain()]

// 404icon

const iconError: any = {
  SpookySwap: 'https://assets.spooky.fi/icons/404.png',
  GoatSwap: 'https://assets.spooky.fi/404goat.png',
}

export const iconErrorMap = iconError[getDefaultChain()]

// confirm modal state
const pendingModal: any = {
  SpookySwap: 'https://assets.spooky.fi/happy_grim.gif',
  GoatSwap: 'https://assets.spooky.fi/happy_goat_new.gif',
}

export const pendingModalMap = pendingModal[getDefaultChain()]

// not found
const errorImgMap: any = {
  SpookySwap: darkImage,
  GoatSwap: 'https://assets.spooky.fi/404goat.png',
}

export const errorImg = errorImgMap[getDefaultChain()]

// stakeIcons
const xBooStakeMap: Record<string, React.ElementType | string> = {
  SpookySwap: XBooIcon,
  GoatSwap: 'https://assets.spooky.fi/xgoat-logo.png',
}

export const XBooStake = xBooStakeMap[getDefaultChain()]

const booStakeMap: any = {
  SpookySwap: 'https://assets.spooky.fi/tokens/BOO.png',
  GoatSwap: 'https://assets.spooky.fi/goat-logo-nobg.png',
}

export const booStake = booStakeMap[getDefaultChain()]

const stakeSteakMap: any = {
  SpookySwap: 'https://assets.spooky.fi/stakesteak.png',
  GoatSwap: 'https://assets.spooky.fi/stakegoat.png',
}

export const stakeSteak = stakeSteakMap[getDefaultChain()]

// nft icon

const lpIconMap: any = {
  SpookySwap: 'https://assets.spooky.fi/V3NFTs/Gato2.png',
  GoatSwap: 'https://assets.spooky.fi/NFTgoat.png',
}

export const lpIcon = lpIconMap[getDefaultChain()]

const whiskersIconMap: any = {
  SpookySwap: 'https://assets.spooky.fi/V3NFTs/whiskers.png',
  GoatSwap: '',
}

export const whiskersIcon = whiskersIconMap[getDefaultChain()]
