import { Trans } from '@lingui/macro'
import { ChainId, Token } from '@uniswap/sdk-core'
import { useWeb3React } from '@web3-react/core'
import { StyledLoadingButtonSpinner } from 'components/AccountDrawer/AuthenticatedHeader'
import { ButtonPrimary } from 'components/Button'
import { AutoColumn } from 'components/Column'
import Img from 'components/Img'
import { AutoRow, RowBetween } from 'components/Row'
import InfoContainer from 'components/StakeSegmentContainer'
import { BOO_ADDRESS, XBOO_ADDRESS } from 'constants/addresses'
import { DEFAULT_CHAIN_ID } from 'constants/misc'
import useBooSupply, { useBooPrice, useXBooRatio } from 'hooks/useBooBalance'
import { useBooLand, useSpook } from 'hooks/useContract'
import useGasPrice from 'hooks/useGasPrice'
import useGet7DayAvgYearFees from 'hooks/useGet7DayAvgYearFees'
import { useBooLandStake } from 'hooks/useStake'
import { useBooLandUnstake } from 'hooks/useUnstake'
import { useTokenBalance } from 'lib/hooks/useCurrencyBalance'
import { Dots } from 'pages/Pool/styled'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Button } from 'rebass'
import styled from 'styled-components'
import { BREAKPOINTS, Separator, ThemedText } from 'theme'
import { booStake, NavbarIcon, stakeSteak, XBooStake } from 'theme/assets'
import { approve } from 'utils/callhelpers'
import { registerToken } from 'utils/registerToken'

import StakeModal from './StakeModal'
import UnstakeModal from './UnstakeModal'

const TitleRow = styled(RowBetween)`
  color: ${({ theme }) => theme.accent1_dark};
  font-size: 2rem;
  display: flex;
  text-align: left;
  padding-left: 10px;
  padding-right: 10px;
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    font-size: 1.5rem;
  }
`

const BridgeWrapper = styled.div`
  background: ${({ theme }) => theme.surface1};
  width: 100%;
  max-width: 800px; /* Adjust if needed */
  margin: 0;
  padding: 24px;
  display: flex; /* Use flexbox to split into halves */
  justify-content: space-between; /* Space between the two sections */
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    flex-direction: column;
  }
`

const LeftSide = styled.div`
  flex: 1;
  margin-right: 30px;
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    margin-right: 0;
  }
`

const RightSide = styled.div`
  flex: 1;
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    margin-left: 0;
  }
  padding: 20px;
  background: linear-gradient(to top, rgba(115, 204, 231, 0.1), rgba(115, 204, 231, 0)) 0% 0%,
    linear-gradient(to bottom, rgba(115, 204, 231, 0.1), rgba(115, 204, 231, 0)) 0% 100%,
    linear-gradient(to right, rgba(115, 204, 231, 0.1), rgba(115, 204, 231, 0)) 100% 0%,
    linear-gradient(to left, rgba(115, 204, 231, 0.1), rgba(115, 204, 231, 0)) 0% 0%;
  background-size: 100% 50%, 100% 50%, 50% 100%, 50% 100%;
  background-repeat: no-repeat;

  display: flex;
  flex-direction: column;
  align-items: center; /* Center items horizontally */
  justify-content: center; /* Center items vertically */
`
const StakeContainer = styled.div`
  align-items: center;
  background: ${({ theme }) => theme.surface1};
  width: 100%;
  max-width: 1000px;
  min-width: 0;
  border-radius: 16px;
  border: 1px solid ${({ theme }) => theme.surface3};
  margin: 0;
  padding: 24px 24px 0 24px;
`

const StakeTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 0;
  margin-bottom: 24px;
  padding: 0px;
  // background: linear-gradient(
  //   90deg,
  //   rgba(${({ theme }) => theme.chain_250}, 0.2) 0%,
  //   rgba(${({ theme }) => theme.chain_250}, 0.1) 50%,
  //   rgba(${({ theme }) => theme.chain_250}, 0) 100%
  // );
  // border-bottom: 1px solid ${({ theme }) => theme.neutral3};
`

const InfoText = styled.p`
  font-size: 14px;
`

const Upper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 20px;
  // padding: 20px;
`

const ButtonStakeContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 5px;
`

const ButtonDisplay = styled(Button)`
  color: ${({ theme }) => theme.neutral2};
  background: none;
  cursor: pointer;
`

const ButtonStake = styled(ButtonPrimary)`
  border-radius: 24px;
  padding: 5px;
  margin: 5px;
  max-width: 70%;
`

const StatWrapper = styled.div`
  display: flex;
  margin: 20px;
  flex-direction: column;
`

const StatContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
`

const StatTitle = styled.div`
  display: flex;
  width: 100%;
  color: ${({ theme }) => theme.accent1_dark};
`

const StatValue = styled.div`
  display: flex;
  width: 100%;
  align-items: right;
  justify-content: right;
  text-align: right;
`

const StatBooIcon = styled.div`
  display: flex;
  margin-right: 5px;
`

// const ProgressWrapper = styled.div`
//   width: 100%;
//   margin-top: 1rem;
//   height: 4px;
//   border-radius: 4px;
//   background-color: ${({ theme }) => theme.surface2};
//   position: relative;
// `

// const Progress = styled.div<{ status: 'for' | 'against'; percentageString?: string }>`
//   height: 4px;
//   border-radius: 4px;
//   background-color: ${({ theme, status }) => (status === 'for' ? theme.chain_250 : theme.critical)};
//   width: ${({ percentageString }) => percentageString ?? '0%'};
// `

const Opacity = styled.div`
  display: flex;
  opacity: 0.5;
`

const BooContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
`

enum CustomApprovalState {
  UNKNOWN,
  NOT_APPROVED,
  PENDING,
  APPROVED,
}

export default function StakeBoo() {
  const { account } = useWeb3React()

  const BooLandContract = useBooLand()
  const Boo = useSpook()
  const gasPrice = useGasPrice()
  const xBooRatio = useXBooRatio()
  const booSupply = useBooSupply(XBOO_ADDRESS[ChainId.FANTOM])
  const booSupplyFormated = parseFloat(booSupply.toString()) / 1e18

  const xBooRatioFormatted = xBooRatio ? parseFloat(xBooRatio.toString()) / 1e18 : 0
  const [claimableBoo, setClaimableBoo] = useState<number | null>(0)

  const { chainId } = useWeb3React()
  const chainIdOrDefault = chainId ?? DEFAULT_CHAIN_ID

  const isGoat = chainIdOrDefault === ChainId.GOAT_TESTNET
  const stakeTokenName = isGoat ? 'GOAT' : 'BOO'

  useEffect(() => {
    const fetchBooBalanceInXBOO = async () => {
      if (!account) return

      try {
        // Fetch the BOO balance in the xBOO contract
        const booBalance = await BooLandContract?.BOOBalance(account)

        const formattedBooBalance = booBalance ? parseFloat(booBalance.toString()) / 1e18 : 0 // Adjust decimals based on actual token decimals
        setClaimableBoo(formattedBooBalance)
      } catch (error) {
        console.error('Failed to fetch BOO balance from xBOO contract:', error)
      }
    }

    fetchBooBalanceInXBOO()
  }, [account, BooLandContract])

  const BOO_TOKEN = new Token(ChainId.FANTOM, BOO_ADDRESS[ChainId.FANTOM], 18, 'BOO', 'Boo')
  const XBOO_TOKEN = new Token(ChainId.FANTOM, XBOO_ADDRESS[ChainId.FANTOM], 18, 'xBOO', 'xBoo')

  const { onStake } = useBooLandStake()
  const { onUnstake } = useBooLandUnstake()

  const [pendingTx, setPendingTx] = useState(false)
  const [, setShowConfirm] = useState(false)
  const [isStakeModalOpen, setIsStakeModalOpen] = useState(false)
  const [isUnstakeModalOpen, setIsUnstakeModalOpen] = useState(false)

  const booBalance = useTokenBalance(account, BOO_TOKEN)
  const xbooBalance = useTokenBalance(account, XBOO_TOKEN)

  const booPrice = useBooPrice()
  const { booUsdPrice: priceBoo } = booPrice
  const priceBooPrecise = Number(priceBoo)

  const yearlyFees = useGet7DayAvgYearFees()

  const tvl = useMemo(() => {
    if (booSupplyFormated && priceBooPrecise) {
      const totalBoo = Number(booSupplyFormated)

      return totalBoo * priceBooPrecise
    }
    return 0
  }, [booSupplyFormated, priceBooPrecise])

  const claimBooBalance = useMemo(() => {
    if (claimableBoo && priceBooPrecise) {
      return claimableBoo * priceBooPrecise
    }
    return 0
  }, [claimableBoo, priceBooPrecise])

  const tradeFeeAPR = (yearlyFees / tvl) * 100
  const [approvalState, setApprovalState] = useState<CustomApprovalState>(CustomApprovalState.UNKNOWN)

  const handleApprove = useCallback(async () => {
    if (!Boo || !BooLandContract) {
      console.error('Boo or BooLandContract is null')
      return
    }

    try {
      setApprovalState(CustomApprovalState.PENDING)
      const tx = await approve(Boo, BooLandContract, gasPrice)
      if (tx) {
        setApprovalState(CustomApprovalState.APPROVED)
      } else {
        setApprovalState(CustomApprovalState.NOT_APPROVED)
      }
    } catch (error) {
      console.error('Approval failed', error)
      setApprovalState(CustomApprovalState.NOT_APPROVED)
    }
  }, [Boo, BooLandContract, gasPrice])

  const allowanceCheck = useMemo(
    () => async () => {
      if (Boo && BooLandContract && account) {
        const allowance = await Boo.allowance(account, BooLandContract.address)
        return allowance
      }
      return null
    },
    [Boo, BooLandContract, account]
  )

  useEffect(() => {
    const checkApproval = async () => {
      const allowance = await allowanceCheck()
      if (allowance && allowance.gt(0)) {
        setApprovalState(CustomApprovalState.APPROVED)
      } else {
        setApprovalState(CustomApprovalState.NOT_APPROVED)
      }
    }

    checkApproval()
  }, [allowanceCheck])

  const handleStake = () => {
    setIsStakeModalOpen(true)
  }

  const handleUnstake = () => {
    setIsUnstakeModalOpen(true)
  }

  const registerXBOO = useCallback(() => {
    return registerToken(
      '0xa48d959AE2E88f1dAA7D5F611E01908106dE7598',
      'xBOO',
      18,
      'https://assets.spooky.fi/tokens/xBOO.png'
    )
  }, [])

  const goatTvlPlaceholder = 5110527.58
  const goatAprPlaceholder = 15.34

  return (
    <>
      <StakeModal
        max={booBalance}
        isOpen={isStakeModalOpen}
        onConfirm={onStake}
        tokenAddr={BOO_ADDRESS[ChainId.FANTOM]}
        tokenName={BOO_TOKEN.symbol}
        setAttemptingTxn={setPendingTx}
        setShowConfirm={setShowConfirm}
        onDismiss={() => setIsStakeModalOpen(false)}
      />
      <UnstakeModal
        max={xbooBalance}
        isOpen={isUnstakeModalOpen}
        onConfirm={onUnstake}
        tokenAddr={XBOO_ADDRESS[ChainId.FANTOM]}
        tokenName="xBOO"
        setAttemptingTxn={setPendingTx}
        setShowConfirm={setShowConfirm}
        onDismiss={() => setIsUnstakeModalOpen(false)}
      />
      <AutoColumn gap="lg" justify="center">
        <AutoColumn gap="lg" style={{ width: '100%', marginTop: '100px' }}>
          {!isGoat && (<InfoContainer />)}
        </AutoColumn>
        <StakeContainer>
          <StakeTitleContainer>
            <AutoColumn gap="lg" style={{ width: '100%', marginTop: '0px' }}>
              <TitleRow padding="0">
                <Trans>
                  {' '}
                  Stake {stakeTokenName}, Receive x{stakeTokenName} Immediately{' '}
                </Trans>
              </TitleRow>
              {/*<TitleRow padding="0">
                <ThemedText.SubHeader>
                  <Trans> Get xBOO buyback receipt tokens here for step 2. </Trans>
                </ThemedText.SubHeader>
              </TitleRow>*/}
            </AutoColumn>
          </StakeTitleContainer>
          <AutoRow style={{ width: '100%', justifyContent: 'center', flexWrap: 'nowrap' }}>
            <BridgeWrapper>
              <LeftSide>
                <AutoRow
                  style={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginBottom: '10px',
                    display: 'flex',
                    flexWrap: 'wrap',
                  }}
                >
                  <Upper>
                    {/* <ProgressWrapper>
                      <Progress
                        status="for"
                        percentageString={booBalance?.greaterThan(0) ? `${booBalance.toFixed(0) ?? 0}%` : '0%'}
                      />
                    </ProgressWrapper> */}
                    <AutoRow
                      style={{
                        alignItems: 'center',
                        justifyContent: 'space-evenly',
                        marginBottom: '10px',
                        display: 'flex',
                        flexWrap: 'wrap',
                      }}
                    ></AutoRow>
                    <AutoRow
                      style={{
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        marginBottom: '10px',
                        display: 'flex',
                        flexWrap: 'nowrap',
                      }}
                    >
                      <BooContainer>
                        1 x{stakeTokenName}
                        <Opacity>
                          {typeof XBooStake === 'string' ? (
                            <img src={XBooStake} alt="xBoo Stake Icon" width={105} height={105} />
                          ) : (
                            <XBooStake width={90} height={90} />
                          )}
                        </Opacity>
                      </BooContainer>
                      =
                      <BooContainer>
                        {isGoat
                          ? `1.57`
                          : xBooRatioFormatted.toLocaleString(undefined, {
                              maximumFractionDigits: 4,
                            })}
                        &nbsp;{stakeTokenName}
                        <Opacity>
                          <NavbarIcon width={90} height={90} />
                        </Opacity>
                      </BooContainer>
                    </AutoRow>
                    <Separator />
                    <StatWrapper>
                      {account && (
                        <>
                          <StatContainer>
                            <StatTitle>Claimable {stakeTokenName}</StatTitle>
                            <StatValue>
                              {claimBooBalance !== null || claimBooBalance !== undefined ? (
                                `$${claimBooBalance.toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}`
                              ) : (
                                <StyledLoadingButtonSpinner />
                              )}
                            </StatValue>
                          </StatContainer>
                          <StatValue>
                            <StatBooIcon>
                              <Img src={booStake} alt="New Info" width="24px" />
                            </StatBooIcon>
                            {claimableBoo !== null && claimBooBalance !== undefined ? (
                              claimableBoo?.toLocaleString(undefined, {
                                maximumFractionDigits: 5,
                              })
                            ) : (
                              <StyledLoadingButtonSpinner />
                            )}
                          </StatValue>
                        </>
                      )}
                      <StatContainer>
                        <StatTitle> TVL </StatTitle>
                        {isGoat ? (
                          `$${goatTvlPlaceholder.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}`
                        ) : (
                          <StatValue>
                            {tvl === 0 ? (
                              <StyledLoadingButtonSpinner />
                            ) : (
                              `$${tvl.toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}`
                            )}
                          </StatValue>
                        )}
                      </StatContainer>
                      <StatContainer>
                        <StatTitle>Approximate APR</StatTitle>
                        {isGoat ? (
                          <StatValue>
                            {goatAprPlaceholder.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                            %
                          </StatValue>
                        ) : (
                          <StatValue>
                            {tradeFeeAPR === Infinity ||
                            tradeFeeAPR === null ||
                            tradeFeeAPR === 0 ||
                            tradeFeeAPR === undefined ||
                            isNaN(tradeFeeAPR) ? (
                              <StyledLoadingButtonSpinner />
                            ) : (
                              `${tradeFeeAPR.toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}%`
                            )}
                          </StatValue>
                        )}
                      </StatContainer>
                    </StatWrapper>
                  </Upper>
                  <Separator />
                  {account && (
                    <ButtonContainer>
                      {approvalState !== CustomApprovalState.APPROVED ? (
                        <ButtonPrimary
                          onClick={handleApprove}
                          disabled={approvalState === CustomApprovalState.PENDING || isGoat}
                          width="100%"
                        >
                          {approvalState === CustomApprovalState.PENDING ? (
                            <Dots>
                              <Trans>Approving {stakeTokenName}</Trans>
                            </Dots>
                          ) : (
                            <Trans>Approve {stakeTokenName}</Trans>
                          )}
                        </ButtonPrimary>
                      ) : (
                        <>
                          <ButtonStakeContainer>
                            <ButtonStake onClick={handleStake} disabled={pendingTx}>
                              <Trans>Stake</Trans>
                            </ButtonStake>

                            <ButtonStake onClick={handleUnstake} disabled={pendingTx}>
                              <Trans>Unstake</Trans>
                            </ButtonStake>
                          </ButtonStakeContainer>
                        </>
                      )}
                      <ButtonDisplay variant="text" onClick={registerXBOO} disabled={isGoat}>
                        Display x{stakeTokenName} in Wallet
                      </ButtonDisplay>
                    </ButtonContainer>
                  )}
                </AutoRow>
              </LeftSide>
              <RightSide>
                <Img src={stakeSteak} alt="New Info" width="240px" />
                <InfoText>
                  Stake {stakeTokenName} here and receive x{stakeTokenName} as receipt representing your share of the
                  pool. This pool automatically compounds and earns a part of DEX swap fees to buy back {stakeTokenName}
                  , which means the x{stakeTokenName} to {stakeTokenName} ratio will grow over time!
                  <br />
                  <br />
                  Like liquidity providing (LP), you will earn fees according to your share in the pool, and your x
                  {stakeTokenName} receipt is needed as proof when claiming the rewards.
                </InfoText>
              </RightSide>
            </BridgeWrapper>
          </AutoRow>
        </StakeContainer>
        {!isGoat && (<><StakeContainer>
          <StakeTitleContainer>
            <AutoColumn gap="lg" style={{ width: '100%', marginTop: '0px' }}>
                <TitleRow padding="0" style={{ justifyContent: 'center', textAlign: 'center' }}>
                  <a href="https://v2.spooky.fi/#/pools" target="_blank" rel="noopener noreferrer">
                    <Trans>Missing tokens? Unstake from Step 2 here</Trans>
                  </a>
                </TitleRow>
              <TitleRow padding="0" style={{ justifyContent: 'center', textAlign: 'center' }}>
                <ThemedText.MediumHeader>
                  <Trans>No Step 2 pools are currently active.</Trans>
                </ThemedText.MediumHeader>
              </TitleRow>
                <TitleRow padding="0" style={{ justifyContent: 'center', textAlign: 'center' }}>
                  <ThemedText.SubHeader>
                    <Trans>All inactive pools are available on Legacy Spooky V2</Trans>
                  </ThemedText.SubHeader>
                </TitleRow>
            </AutoColumn>
          </StakeTitleContainer>
        </StakeContainer> </>)}
      </AutoColumn>
    </>
  )
}
